
import { Watch, Mixins, Component, Ref, InjectReactive } from "vue-property-decorator";
import TablePagination from "@/mixins/tablePagination";
import Resize from "@/mixins/resize";

import UploadImg from "@/components/uploadImg.vue";

import ETable from "@/components/ETable.vue";

import { Form } from "element-ui/types";

import { bookServiceList, bookServiceEdit } from "@/api/booking";

import { cloneDeep } from "lodash";

import { IServiceConfigQueryForm, IServiceConfigRecord } from "@/types/booking";

@Component({
    components: {
        ETable,
        UploadImg
    }
})
export default class ServiceConfig extends Mixins(TablePagination, Resize) {
    @InjectReactive() currentTab!: string | number;
    @Ref("editFormRef") readonly EditFormRef!: Form;

    queryForm: IServiceConfigQueryForm = {
        deviceClassId: "",
        name: "",
    }

    deviceClassOptions = [];

    tableData: Array<IServiceConfigRecord> = [];

    loading = false;

    btnLoading = false;

    editRowInfo: IServiceConfigRecord = {
        id: "",
        deviceClassName: "",
        name: "",
        imgUrl: [],
        remark: "",
    };

    rules = {
        name: [{ required: true, message: "请输入服务显示名称", trigger: "blur" }],
        imgUrl: [{ required: true, type: "array", message: "请上传封面", trigger: "blur" }],
    };

    columns = [
        { label: "服务显示名称", prop: "name" },
        { label: "设备分类", prop: "deviceClassName" },
        { label: "封面", prop: "imgUrl", align: "center", width: 130 },
        { label: "操作人", prop: "modifiedBy" },
        { label: "最后操作时间", prop: "modifiedTime" },
        { label: "操作", prop: "action", align: "center", width: 100 },
    ];

    dialogVisible = false;

    mounted() {
        this.windowResize(100)
    }

    bookServiceList() {
        this.loading = true;
        bookServiceList().then((res) => {
            this.tableData = res.data.list || [];
        }).finally(() => {
            this.loading = false;
        })
    }

    editRow(row: IServiceConfigRecord) {
        this.editRowInfo = cloneDeep(row);
        this.dialogVisible = true;
        this.editRowInfo.imgUrl = row.imgUrl ? [{ url: row.imgUrl }] : []
    }

    confirm() {
        this.EditFormRef.validate().then(() => {
            const imgUrl = this.editRowInfo?.imgUrl[0]?.url || "";
            this.btnLoading = true;
            bookServiceEdit({ ...this.editRowInfo, imgUrl }).then((res) => {
                this.bookServiceList();
                this.handleClose();
            }).finally(() => {
                this.btnLoading = false;
            })
        })
    }

    cancel() {
        this.handleClose();
    }

    handleClose() {
        this.EditFormRef.resetFields();
        this.editRowInfo.imgUrl = [];
        this.dialogVisible = false;
    }

    @Watch("currentTab", { immediate: true })
    getData() {
        if (this.currentTab != 3) return;
        this.bookServiceList();
    }
}
